import React from "react";
import './App.css';

function HeaderBar() {
    return(
           <div className="HeaderBar">
            corporate Logo here
               <div className="FullLogo">
                   logo here
               </div>
               <div className="mainbuttons">
                   <button className="btn ">HOME</button>
                   <button className="btn ">BUY</button>
                   <button className="btn ">SELL</button>
                   <button className="btn ">BLOGS</button>
                   <button className="btn ">ABOUT</button>
                   <button className="btn ">MARKET SNAPSHOT</button>
               </div>
               <div className="loginButtons">
                   <button className="btn2"> Register</button>

                   <button className="btn2"> Sign in</button>
               </div>



           </div>

        )

}

export default HeaderBar;
