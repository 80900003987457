import React from "react";
import './App.css';

function Portrait() {

    return(

            <div className="Portrait">
                <img src="../apartment.webp"  width="100%" height="100%"/>
                <text className="centered">
                   Jan Gilbert Realty's Experts
                </text>
                <div className="centered2">
               Are here to help
                </div>

                <text className="centered3">
                    Whether you’re buying your dream home or selling your
                    current one, our dedicated real estate experts are happy to
                    help while providing exceptional customer service.
                </text>

            </div>



        )

}

export default Portrait;
