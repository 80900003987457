import logo from './logo.svg';
import './App.css';
import Portrait from "./Portrait";
import React from "react";
import PhoneContact from "./PhoneContact";
import SaleListings from "./SaleListings";
import SocialBar from './SocialBar'
import HeaderBar from "./HeaderBar";

function App() {
  return (
    <div>
        <div className="stickyHeader">
            <SocialBar/>
            <HeaderBar/>
        </div>

        <Portrait/>
    </div>
  );
}

export default App;
